<template>
	<div style="height: 100%;">
		<template v-if="theme.is_mobile && theme.width < 750">
			<div class="head">
				<div>{{params.page}} / {{Math.ceil(params.total/params.perPage)}}，共 {{params.total}} 条记录</div>
				<div @click="popup_filter = true">筛选</div>
			</div>
			<div class="wrap">
				<div style="margin-bottom: 10px; padding: 10px; background-color: #fff;" v-for="client in clients" :key="client.id">
					<div>{{client.name}}</div>
					<div v-if="client.created_at">{{client.created_at}}</div>
				</div>
			</div>
			<van-pagination v-model="params.page" :total-items="params.total" :items-per-page="params.perPage" @change="(v) => { getClients({ ...params, page: v }) }"></van-pagination>
		</template>
		<template v-else>
			<main-table>
				<el-form slot="header" :inline="true" :model="params" :size="theme.size">
					<el-form-item>
						<el-input v-model="params.keywords" placeholder="名称/手机/邮箱/社交账号" clearable></el-input>
					</el-form-item>
					<el-form-item>
						<el-input v-model="params.goods" placeholder="内部单号/平台单号/快递单号/产品" clearable></el-input>
					</el-form-item>
					<el-form-item>
						<el-select style="width: 100%;" placeholder="请选择标签" v-model="params.tags" :remote-method="remoteMethod" :loading="tags_loading" :multiple-limit="5" remote multiple filterable default-first-option>
							<el-option v-for="(tag, t) in tags" :key="t" :label="tag" :value="tag"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item>
						<el-select v-model="params.source" placeholder="来源渠道" style="width: 100%;" filterable multiple collapse-tags>
							<el-option v-for="(source, s) in sources" :key="s" :label="source" :value="s"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item>
						<el-select v-model="params.former_id" placeholder="前业务" filterable multiple collapse-tags>
							<el-option v-for="staff in staffs" :key="staff.id" :label="staff.name" :value="staff.id"></el-option>
						</el-select>
					</el-form-item>
					<!-- <el-form-item>
						<el-select v-model="params.source" placeholder="类型" style="width: 100%;" clearable filterable>
							<el-option v-for="(source, s) in fields.sources" :key="s" :label="source" :value="s"></el-option>
						</el-select>
					</el-form-item> -->
					<el-form-item>
						<el-select v-model="params.status" placeholder="请选择状态" style="width: 100%;" filterable multiple collapse-tags>
							<el-option v-for="(status, s) in fields.statuses" :key="s" :label="status" :value="s"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item v-if="!admin_user.shop_id">
						<el-select v-model="params.shop_id" placeholder="城市站点" filterable clearable>
							<el-option v-for="shop in shops" :key="shop.id" :label="shop.name" :value="shop.id"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item>
						<el-cascader style="width: 100%;" placeholder="请选择所属地区" v-model="params.regions" :options="tree_regions" :props="{expandTrigger: 'hover', value: 'id', label: 'name', checkStrictly: true}" filterable clearable></el-cascader>
					</el-form-item>
					<el-form-item>
						<el-select slot="label" v-model="params.date_type" placeholder="请选择时间类型" filterable>
							<el-option label="录入时间" value="created"></el-option>
							<el-option label="流入时间" value="updated"></el-option>
						</el-select>
						<el-date-picker v-model="params.date" :picker-options="$config.DATE_PICKER" type="datetimerange" value-format="yyyy-MM-dd HH:mm:ss" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"></el-date-picker>
					</el-form-item>
					<el-form-item>
						<el-button type="primary" @click="getClients({ ...params, page: 1})">查询</el-button>
					</el-form-item>
				</el-form>
				<el-table slot="table" ref="clients_table" class="scroll-wrapper absolute" :data="clients" height="100%" :size="theme.size">
					<el-table-column label="账户名称" min-width="120">
						<template slot-scope="scope">
							<el-link type="primary" @click="$refs['show_client'].open({id: scope.row.id})"><i class="el-icon-user"></i> {{scope.row.name}}</el-link>
							<p v-if="scope.row.mobile"><i class="el-icon-mobile-phone"></i> {{scope.row.mobile}}</p>
							<p>
								<el-tag size="mini" style="margin-right: 10px;" v-for="tag in scope.row.tags" :key="tag.id">{{tag.name}}</el-tag>
							</p>
						</template>
					</el-table-column>
					<el-table-column label="归属">
						<template slot-scope="scope">
							<p>{{scope.row.shop ? scope.row.shop.name : theme.group}}</p>
							<p v-if="scope.row.former">{{scope.row.former.name}}</p>
						</template>
					</el-table-column>
					<el-table-column label="状态" min-width="100">
						<template slot-scope="scope">
							<el-tag size="mini" :type="tag_colors[scope.row.status] || '未知'">{{fields.statuses[scope.row.status] || '未知'}}</el-tag>
						</template>
					</el-table-column>
					<el-table-column label="订单" min-width="280">
						<template slot-scope="scope">
							<div v-if="scope.row.orders && scope.row.orders.length">
								<!-- <p>{{scope.row.orders[0].no}} {{ostatuses[scope.row.orders[0].status]}} {{scope.row.orders[0].paid_price/100}} 元 <template v-if="scope.row.orders[0].entered_at">下单于 {{scope.row.orders[0].entered_at}}</template> <template v-if="scope.row.orders[0].express_no">快递单号 {{scope.row.orders[0].express_no}}</template></p> -->
								<p>{{scope.row.orders[0].paid_price/100}} 元 {{scope.row.orders[0].entered_at || scope.row.orders[0].created_at}} 下单于 {{order_fields.sources[scope.row.orders[0].source]}} <template v-if="scope.row.orders[0].express_no"> {{expresses[scope.row.orders[0].express] || '未知'}}单号 {{scope.row.orders[0].express_no}}</template></p>
								<p style="color: #999; line-height: 1.2; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;" v-for="goods in scope.row.orders[0].order_goods" :key="goods.id">{{goods.title}}</p>
								<p v-if="scope.row.orders.length > 1">等共 {{scope.row.orders.length }} 个订单</p>
							</div>
							<p v-else>暂无～</p>
						</template>
					</el-table-column>
					<el-table-column label="跟进记录" min-width="210">
						<template slot-scope="scope">
							<div v-if="scope.row.client_follows && scope.row.client_follows.length">
								<p>{{follow_fields.types[scope.row.client_follows[0].type] || '未知操作'}}于 {{scope.row.client_follows[0].created_at}}</p>
								<p style="font-size: 12px; color: rgb(153, 153, 153); line-height: 1.2; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">{{scope.row.client_follows[0].comment}}</p>
								<p style="font-size: 12px; color: rgb(183, 160, 106); line-height: 1.2; font-weight: bold;" v-if="scope.row.client_follows[0].course">{{ffollow_fields.courses[scope.row.client_follows[0].course]}}，{{ffollow_fields.learns[scope.row.client_follows[0].learn]}}</p>
								<p v-if="scope.row.client_follows.length > 1">等共 {{scope.row.client_follows.length }} 条跟进</p>
							</div>
							<p v-else>暂无～</p>
						</template>
					</el-table-column>
					<el-table-column label="操作时间" width="180" v-if="theme.width >= 1240">
						<template slot-scope="scope">
							<p>流入：{{scope.row.updated_at}}</p>
							<p>录入：{{scope.row.created_at}}</p>
						</template>
					</el-table-column>
				</el-table>
				<el-pagination
					slot="footer"
					:layout="$config.PAGE_LAYOUT"
					:page-sizes="$config.PAHE_SIZES"
					:page-size="params.perPage"
					:current-page="params.page"
					:pager-count="theme.is_mobile ? 3 : 18"
					:total="params.total"
					@size-change="(v) => { getClients({ ...params, page: 1, perPage: v }) }"
					@current-change="(v) => { getClients({ ...params, page: v }) }"
					background>
				</el-pagination>
			</main-table>
			<show-client ref="show_client" @refresh="getClients(params)"></show-client>
		</template>
	</div>
</template>

<style>
	.el-picker-panel__footer .el-button--text.el-picker-panel__link-btn {
		display: none;
	}
	.mobile {
		display: flex; flex-direction: column; justify-content: space-between; height: 100%;
	}
	.mobile .head {
		display: flex; line-height: 45px; color: #666; font-size: 14px; border-bottom: 1px solid #ccc; padding: 0 10px; justify-content: space-between; background-color: #fff;
	}
	.mobile .wrap {
		flex: 1; background-color: #f5f5f5; overflow: auto;
	}
</style>

<script>
	import { mapState } from 'vuex';
	import mainTable from '@/components/main-table';
	import showClient from './show';

	export default {
		components: {
			mainTable,
			showClient
		},
		computed: {
			...mapState(['theme', 'regions', 'admin_user', 'client_follows', 'models']),
			tree_regions () {
				return this.$utils.buildTree(this.regions);
			},
			fields () {
				return this.models.clients || {};
			},
			sources () {
				if (!this.models) return [];
				const { clues } = this.models;
				return clues.sources || [];
			},
			order_fields () {
				return this.models.orders || {};
			},
			follow_fields () {
				return this.models.client_follows || {};
			},
			tag_colors () {
				return {
					'pending': 'info',
					'uncontact': 'warning',
					'following': 'warning',
					'leaning': 'warning',
					'added': 'success',
					'dealed': 'success',
					'intention': 'success',
					'invalided': 'danger'
				};
			}
		},
		methods: {
			async remoteMethod (query) {
				if (query !== '') {
					this.tags_loading = true;
					const res = await this.$http.get(this.$api.URI_TAGS, { params: {action: 'search', word: query} });
					this.tags = res.data.result.map((tag) => { return tag.name; });
					this.tags_loading = false;
				} else {
					this.tags = [];
				}
			},
			async getClients (params, loading = false) {
				const res = await this.$http.get(this.$api.URI_CLIENTS, {params: {...params, masterless: 1}, headers: { loading }});
				const { code, msg, result } = res.data;
				if (code != 0) return this.$message.error(msg);
				this.clients = result.data;
				this.shops = result.shops;
				this.expresses = result.expresses;
				this.staffs = result.staffs;
				this.params = {
					...params,
					total: result.total,
					perPage: parseInt(result.per_page)
				}
			}
		},
		data() {
			return {
				tags: [],
				staffs: [],
				shops: [],
				expresses: [],
				clients: [],
				client: {},
				tags_loading: false,
				params: {
					date_type: 'created',
					perPage: 10
				}
			}
		},
		async created () {
			this.getClients(this.params, true);
		}
	};
</script>